body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.callout {
  border-left:5px solid #e79b46  ;
}

.callout-advertencia{
  border-left:5px solid #feb272;
}

.callout-info{
  border-left:5px solid #e3de50;
}

.callout-guardar{
  border-left: 5px solid #198754;
}

.disabled {
  pointer-events: none; /* <-- here's your huckleberry */
  opacity: 0.65;
}

.card-header{
  background-color:#edf0f1  !important;
}

table{
  margin-bottom: 10px;
  width: 100%;
}
table th{
  color:#53AAE0 !important;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: left;
}

