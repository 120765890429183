.logo{
    position: relative; 
    z-index: 1;
}

.navegador{
    right: 15px;
    margin-top: 10px;
    position:absolute; 
    z-index:2; 
}

.login-logo-subdireccion{
    width: 70%;
 }

 .login-logo-ministerio{
    height: 6%;
 }
 .text-secondary{
    color:#5B5A5D;
  }