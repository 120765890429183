@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

body{
	margin-left:auto;
	margin-right:auto;
	padding:0px;

}
#contFooter{
	margin-left:auto;
	margin-right:auto;
    position: relative;    

}
.img-menu{
    padding: 2em 33%;
}
.bg-primary{
    background-color: #edf0f1  !important;

}

.text-secondary{
  color:#5B5A5D !important;
}
.brand-logo{
    height: 64px;
    margin-left: 25px;      
}

.banner {
    width: 40%;
    height: auto;
    text-align: center;
}
a{
    cursor: pointer;
}
a:link {
    text-decoration: none;
}
.principal :hover{
    border-width: 3px;
    border-color: #53AAE0;
}

.rdt_Table{
    padding-left: 2%;
    padding-right: 2%;
}   
.rdt_TableCol{
    border-bottom: 1px solid black;
}
.rdt_TableHeadRow{
    font-weight: bold;
}
.rdt_Pagination{
    /*position: fixed;
    
    */
    
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    z-index: 1;
}
#cell-1-undefined{
    font-weight: bold;
}


.enunciado-gral{
    color: #7c7d7d;
}

.btn-outline-primary{
    color: rgba(220, 133, 19, 0.6)
}

.btn-outline-primary:hover{
    background-color: rgba(220, 133, 19, 0.6)
}

#img-fast{
    display: inline;
   }  
.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 48
  }

.lg{
    font-size:40px;
    color:#53AAE0;
  }

.amarillo{
    color:#E9BE3E;
  }
.celeste{
    color:#53AAE0;
  }
.rojo{
    color: #D37E32;
  }



#area-Detalle{
  height: 120px;
}

 /* The switch - the box around the slider */
 .switch {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 24px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } 

@media only screen and (max-width: 1000px) {
  .banner {
    width: 80%;
    height: auto;
}
    .brand-logo{
      height: 54px;
   }
}
  
@media only screen and (max-width: 700px) { 
    .banner {
        width: 100%;
        height: auto;
    }
    #img-fast{
        display: none;
       }  

}    

